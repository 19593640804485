import tableState from "../../../templates/table/state";

export default () => ({
  ...tableState(),
  website: {},
  articleCluster: {},
  articleSibling: {},
  article: {},
  locale: {}
});
